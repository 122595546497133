import '../scss/main.scss';
import {Collapse} from 'bootstrap'

function debounce(func, delay) {
  let timeoutId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

function createToast({status, message}) {
  const toastContainer = document.querySelector('#toast');

  const toast = document.createElement('div');
  toast.classList.add('slvt-toast', `slvt-toast-${status.toLowerCase()}`);
  toast.innerHTML = `
    <div class="slvt-toast-content">
      <div class="slvt-toast-icon"></div>
      <div class="slvt-toast-message">
        <span class="slvt-toast-message-status">${status}</span>
        <span class="slvt-toast-message-text">${message}</span>
      </div>
    </div>
    <i class="fa-sharp fa-light fa-xmark slvt-toast-close-btn"></i>
    <div class="slvt-toast-progress"></div>
  `;

  const toastProgress = toast.querySelector('.slvt-toast-progress');
  const closeBtn = toast.querySelector('.slvt-toast-close-btn');

  closeBtn.addEventListener('click', () => {
    toast.classList.remove('active');
    setTimeout(() => {
      toast.remove();
    }, 500);
  });

  toastContainer.appendChild(toast);

  setTimeout(() => {
    toastProgress.classList.add('active');
    toast.classList.add('active');
  }, 100);

  setTimeout(() => {
    toast.classList.remove('active');
  }, 7000);

  setTimeout(() => {
    toast.remove();
  }, 7500);
}

function setCookie(name, value, days) {
  let expires = "";

  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') 
        c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) == 0) 
        return c.substring(nameEQ.length, c.length);
  }

  return null;
}

function enableTracking() {
  function gtag() { dataLayer.push(arguments); }

  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted'
  });

  var gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-H4SWSCFH11';

  var firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(gtagScript,firstScript);
}

(function() {
  function showCookieNotice() {
    const cookieNotice = document.querySelector("#cookie-notice");
    const acceptCookiesBtn = cookieNotice.querySelector("#acceptCookiesBtn");
    const denyCookiesBtn = cookieNotice.querySelector("#denyCookiesBtn");
    const closeBtn = cookieNotice.querySelector('.cookie-close-btn');

    cookieNotice.style.display = 'block';

    acceptCookiesBtn.addEventListener('click', function() {
      setCookie('cookiesAllowed', 'true', 365);
      cookieNotice.style.display = 'none';
      enableTracking();
    });

    denyCookiesBtn.addEventListener('click', function() {
      setCookie('cookiesAllowed', 'false', 365);
      cookieNotice.style.display = 'none';
    });

    closeBtn.addEventListener('click', () => {
      cookieNotice.style.display = 'none';
    });
  }

  function doesCookieExist(name) {
    return document.cookie.split(';').some(function(cookie) {
      return cookie.trim().startsWith(name + '=');
    });
  }

  function areCookiesAllowed() {
    return getCookie('cookiesAllowed') === 'true';
  }

  function init() {
    if (!doesCookieExist('cookiesAllowed')) {
      setTimeout(() => {
        showCookieNotice();
      }, 2000);
    } else {
      if (areCookiesAllowed()) {
        enableTracking();
      }
    }
  }

  init();
})();

(function() {
  const nav = document.querySelector('.main-nav');
  const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1), { threshold: [1] }
  );
  observer.observe(nav);
})();

(function() {
  function onSubmit(event) {
    event.preventDefault();

    const emailAddressInput = this.querySelector('input[type="email"]');
    const submitButton = this.querySelector('button');
    
    submitButton.innerHTML = `<i class="fa-sharp fa-light fa-spinner-third fa-2x fa-spin"></i>`;
    
    const email = emailAddressInput.value.trim();

    fetch('/api/newsletter-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "email": email })
    })
    .then(response => {
      if (response.ok) 
        return response.json();
      throw response
    })
    .then(data => {
      emailAddressInput.value = '';
      submitButton.innerHTML = `<i class="fa-sharp fa-light fa-check fa-2x"></i>`;
      createToast(data);
      setTimeout(() => {
        submitButton.innerHTML = `<i class="fa-sharp fa-light fa-arrow-right fa-2x"></i>`;
      }, 3000);
    })
    .catch(error => {
      submitButton.innerHTML = `<i class="fa-sharp fa-light fa-arrow-right fa-2x"></i>`;
      error.json()
      .then(data => {
        createToast(data);
      })
      .catch(() => {
        createToast({status: 'Error', message: 'An unexpected error occurred. Please try again later.'});
      })
    });
  }

  document.querySelector('#newsletter-form').addEventListener('submit', onSubmit);
})();

(function() {
  document.querySelectorAll('[data-lazy-load]').forEach((el) => {
    const { dataset: { src } } = el;
    const preload = new Image();

    preload.onload = () => {
      el.style.backgroundImage = `url("${src}")`;
      setTimeout(() => {
        el.classList.remove('bg-blur');
      }, 100);
    };

    preload.src = src;
  });
})();

(function() {
  const updateParallax = (el) => {
    const speed = parseFloat(el.getAttribute('data-parallax')) || 0.08;

    const elementRect = el.getBoundingClientRect();

    if (elementRect.top < window.innerHeight && elementRect.bottom >= 0) {
      const translateY = (elementRect.top - window.innerHeight) * speed * -1;

      requestAnimationFrame(() => {
        el.style.transform = `translate3d(0, ${translateY}px, 0)`;
      });
    }
  };

  const updateParallaxAll = () => {
    document.querySelectorAll('[data-parallax]').forEach((el) => {
      updateParallax(el);
    });
  }

  document.querySelectorAll('[data-parallax]').forEach((el) => {
    updateParallax(el);
    setTimeout(() => {
      el.style.opacity = 1;
    }, 100);
  });

  const DEBOUNCE_DELAY = 10;
  const debounceUpdateParallax = debounce(updateParallaxAll, DEBOUNCE_DELAY);

  window.addEventListener('scroll', debounceUpdateParallax);
})();

(function() {
  const marquee = document.querySelector('#marquee');

  if (marquee) {
    const marquee1 = marquee.querySelector('.marquee-1');
    const marquee2 = marquee.querySelector('.marquee-2');
  
    const updateMarquee = () => {
      const elementRect = marquee.getBoundingClientRect();
  
      if (elementRect.top < window.innerHeight && elementRect.bottom >= 0) {
        let pos = window.scrollY - (elementRect.top + elementRect.height);
        requestAnimationFrame(() => {
          marquee1.style.transform = `translate3d(${pos}px, 0, 0)`;
          marquee2.style.transform = `translate3d(-${pos}px, 0, 0)`;
        });
      }
    };
  
    const DEBOUNCE_DELAY = 6;
    const debounceUpdateMarquee = debounce(updateMarquee, DEBOUNCE_DELAY);
  
    window.addEventListener('scroll', debounceUpdateMarquee);
  }
})();

(function() {
  document.querySelectorAll('[data-modal-target]').forEach((el) => {
    el.addEventListener('click', () => {
      const modal = el.getAttribute('data-modal-target');
      const modalEl = document.querySelector(modal);
      if (modalEl.classList.contains("show")) return;
      modalEl.classList.add("show");  
    });
  });
})();

(function() {
  const contactModal = document.querySelector("#contact-modal");
  const contactForm = contactModal.querySelector("#contact-form");

  const modalClose = contactModal.querySelector(".modal-close");
  const modalPrev = contactModal.querySelector(".modal-prev");
  const modalNext = contactModal.querySelector(".modal-next");
  const modalSlides = contactModal.querySelector(".modal-slides");
  const numOfSlides = modalSlides.querySelectorAll(".modal-slide").length;
  const modalProgressBar = contactModal.querySelector(".modal-progress-bar");
  const modalActionNextButtons = contactModal.querySelectorAll("[data-modal-action-button=\"next\"]");
  const modalActionSubmitButton = contactModal.querySelector("[data-modal-action-button=\"submit\"]");

  let dirtySlides = []
  
  const updateNav = () => {
    const activeSlideIndex = parseInt(contactModal.getAttribute("data-active-slide")); 
    const activeSlide = contactModal.querySelector(".modal-slide[data-slide=\"" + activeSlideIndex + "\"]");
    
    modalPrev.removeAttribute("disabled");
    modalNext.removeAttribute("disabled");

    const disableNav = activeSlide.getAttribute("data-modal-nav-disabled");

    if (disableNav == "both") {
      modalNext.setAttribute("disabled", true);
      modalPrev.setAttribute("disabled", true);
      return;
    }

    if (activeSlideIndex == 0) {
      modalPrev.setAttribute("disabled", true);
    }

    modalNext.setAttribute("disabled", true);
  }

  const resetModal = () => {
    dirtySlides = [];

    modalSlides.querySelectorAll(".error-message").forEach((el) => {
      el.innerHTML = "";
    });

    contactModal.setAttribute("data-active-slide", 0);

    modalSlides.querySelectorAll('.modal-slide').forEach((el) => {
      el.classList.remove("in-view");
      el.classList.remove("hidden-top");
      el.classList.add("hidden-bottom");
    });
    
    const activeSlide = contactModal.querySelector(".modal-slide[data-slide=\"0\"]");
    activeSlide.classList.remove("hidden-bottom");
    activeSlide.classList.add("in-view");

    modalActionSubmitButton.textContent = "Submit";

    contactForm.reset();

    updateModalProgressBar(0);
    updateNav();
  }

  const isValidEmail = (email) => {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const performValidation = () => {
    const activeSlideIndex = parseInt(contactModal.getAttribute("data-active-slide"));
    const activeSlide = modalSlides.querySelector(".modal-slide[data-slide=\"" + activeSlideIndex + "\"]");
    const inputField = activeSlide.querySelector("input, select");
    const errorMessage = activeSlide.querySelector(".error-message");

    let errorString = "";
    let hasError = false;

    errorMessage.textContent = errorString;

    if (inputField) {
      if (!inputField.value.trim()) {
        errorString = "Input field cannot be blank."
        hasError = true;
      } else if (inputField.getAttribute("type") === "email" && !isValidEmail(inputField.value)) {
        errorString = "Invalid email address format."
        hasError = true;
      }
    }

    if (errorString) {
      errorMessage.innerHTML = `<i class="fa-sharp fa-light fa-circle-exclamation"></i>${errorString}`;
    }

    if (!activeSlide.hasAttribute("data-modal-nav-disabled")) {
      if (hasError) {
        modalNext.setAttribute("disabled", true);
      } else {
        modalNext.removeAttribute("disabled");
      }
    }

    return hasError;
  }

  const nextSlide = () => {
    performValidation();

    if (modalNext.hasAttribute("disabled")) return;

    const activeSlideIndex = parseInt(contactModal.getAttribute("data-active-slide"));
    const nextSlideIndex = activeSlideIndex + 1;

    if (nextSlideIndex != numOfSlides) {
      const activeSlide = modalSlides.querySelector(".modal-slide[data-slide=\"" + activeSlideIndex + "\"]");
      const nextSlide = modalSlides.querySelector(".modal-slide[data-slide=\"" + nextSlideIndex + "\"]");
      
      const activeInputField = activeSlide.querySelector("input, select");
      activeInputField.blur();

      activeSlide.classList.remove('in-view');
      activeSlide.classList.add('hidden-top');
      nextSlide.classList.remove('hidden-bottom');
      nextSlide.classList.add('in-view');
  
      contactModal.setAttribute("data-active-slide", nextSlideIndex);
      updateModalProgressBar(nextSlideIndex);
      updateNav();

      if (dirtySlides.includes(nextSlideIndex)) { 
        performValidation(); 
      } else { 
        dirtySlides.push(nextSlideIndex); 
      }
    }
  }

  const prevSlide = () => {
    if (modalPrev.hasAttribute("disabled")) return;

    const activeSlideIndex = parseInt(contactModal.getAttribute("data-active-slide"));
    const prevSlideIndex = activeSlideIndex - 1;
    
    if (prevSlideIndex != -1) {
      const activeSlide = modalSlides.querySelector(".modal-slide[data-slide=\"" + activeSlideIndex + "\"]");
      const prevSlide = modalSlides.querySelector(".modal-slide[data-slide=\"" + prevSlideIndex + "\"]");

      activeSlide.classList.remove('in-view');
      activeSlide.classList.add('hidden-bottom');
      prevSlide.classList.remove('hidden-top');
      prevSlide.classList.add('in-view');
  
      contactModal.setAttribute("data-active-slide", prevSlideIndex);
      updateModalProgressBar(prevSlideIndex);
      updateNav();
      performValidation();
    }
  }

  const submitForm = () => {
    const hasError = performValidation();
    if (hasError) return;

    modalActionSubmitButton.innerHTML = `Submitting...<i class="fa-sharp fa-light fa-spinner-third fa-spin"></i>`;

    const formData = new FormData(contactForm);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    fetch('/api/contact-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
    .then(response => {
      if (response.ok)
        return response.json();
      throw response
    })
    .then(data => {
      contactModal.classList.remove("show");
      resetModal();
      createToast(data);
    })
    .catch(error => {
      contactModal.classList.remove("show");
      resetModal();
      error.json()
      .then(data => {
        createToast(data);
      })
      .catch(() => {
        createToast({status: 'Error', message: 'An unexpected error occurred. Please try again later.'});
      })
    });
  };

  const updateModalProgressBar = (activeIndex) => {
    const percentWidth = ((activeIndex + 1) / numOfSlides) * 100
    modalProgressBar.style.width = percentWidth + "%";
  }

  updateModalProgressBar(parseInt(contactModal.getAttribute("data-active-slide")));

  modalClose.addEventListener('click', () => {
    if (contactModal.classList.contains("show")) {
      contactModal.classList.remove("show");
      resetModal();
    }
  });

  modalNext.addEventListener('click', () => {
    nextSlide();
  });

  modalPrev.addEventListener('click', () => {
    prevSlide();
  });

  modalActionSubmitButton.addEventListener('click', () => {
    submitForm();
  });

  document.addEventListener('keypress', function (event) {
    if (contactModal.classList.contains("show")) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        const activeSlideIndex = parseInt(contactModal.getAttribute("data-active-slide"));
        const activeSlide = contactModal.querySelector(".modal-slide[data-slide=\"" + activeSlideIndex + "\"]");

        const hasNextButton = activeSlide.querySelector("[data-modal-action-button=\"next\"]");
        const hasSubmitButton = activeSlide.querySelector("[data-modal-action-button=\"submit\"]");

        if (hasNextButton) nextSlide();
        if (hasSubmitButton) {
          submitForm();
        }
      }
    }
  });

  document.addEventListener('keydown', function(event) {
    if (contactModal.classList.contains("show")) {
      if (event.key === 'Tab') {
          event.preventDefault();
      }
    }
  });

  modalActionNextButtons.forEach((el) => {
    el.addEventListener('click', () => {
      nextSlide();
    });
  });

  modalSlides.querySelectorAll('input, select').forEach((input) => {
    input.addEventListener('input', () => {
      performValidation();
    });
  });

  updateNav();
})();

(function () {
  const planTypeCheckbox = document.querySelector("#plan-type-checkbox");

  const pricing = {
    "essentials": { 0: "$50", 1: "$40"},
    "advanced": { 0: "$90", 1: "$75"}
  }

  if (planTypeCheckbox) {
    const planTypeMonthly = document.querySelector("#plan-type-monthly");
    const planTypeAnnually = document.querySelector("#plan-type-annually");
    const planPriceEss = document.querySelector("#plan-price-ess");
    const planPriceAdv = document.querySelector("#plan-price-adv");

    planTypeCheckbox.addEventListener('change', () => {
      if (planTypeCheckbox.checked) {
        planTypeMonthly.classList.remove("active");
        planTypeAnnually.classList.add("active");
        planPriceEss.textContent = pricing["essentials"][1];
        planPriceAdv.textContent = pricing["advanced"][1];
      } else {
        planTypeAnnually.classList.remove("active");
        planTypeMonthly.classList.add("active");
        planPriceEss.textContent = pricing["essentials"][0];
        planPriceAdv.textContent = pricing["advanced"][0];
      }
    });
  }
})();

(function () {
  const navbar = document.querySelector('.navbar-collapse');

  document.querySelectorAll('.navbar-nav .nav-item > a.nav-link').forEach(function(item) {
    item.addEventListener('click', function(event) {
      event.stopPropagation();
    });
  });

  document.querySelectorAll('.navbar-nav ul.dropdown-menu li a, footer div.footer-links ul li a').forEach(function(link) {
    link.addEventListener('click', function(event) {
      event.preventDefault();

      const target = document.querySelector(this.getAttribute('href'));
      const offset = 120;
      const targetTop = target.getBoundingClientRect().top + window.scrollY - offset;
  
      window.scrollTo({
        top: targetTop,
        behavior: 'smooth'
      });
    });
  });

  navbar.addEventListener('click', () => {
    if (window.innerWidth < 992 && navbar.classList.contains('show')) {
      const navbarCollapse = new Collapse(navbar);
      navbarCollapse.toggle();
    }
  });
})();